import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

import { EcommAPIConfigProvider } from '../../ecomm/config/ecomm-config.provider';
import { EcommFeatureFlagsProvider } from '../../ecomm/config/ecomm-feature-flags.provider';
import { EcommModule } from '../../ecomm/ecomm.module';
import {
  Config,
  ConfigProvider,
} from '../../ecomm/providers/config/config.provider';
import {
  ILegacyAnalyticsService,
  LegacyAnalyticsServiceProvider,
} from '../../ecomm/providers/legacy-providers/analytics.service';
import {
  ILegacySeoService,
  LegacySeoServiceProvider,
} from '../../ecomm/providers/legacy-providers/seo.service';
import {
  ActiveOfferComponent,
  AdditionalRecommendationCardComponent,
  AddressLinkComponent,
  AnimationComponent,
  BadgeComponent,
  BagCountComponent,
  BreadcrumbComponent,
  CartLocationComponent,
  CheckboxComponent,
  CommonModalComponent,
  ConnectedBagCountComponent,
  DesignationComponent,
  ExternalLinkConfirmationModalComponent,
  FeatureFlagsComponent,
  FlavorCardComponent,
  FormErrorComponent,
  GenericErrorComponent,
  GoogleRecaptchaBrandingComponent,
  HeatScaleComponent,
  IconComponent,
  LocationAmenitiesComponent,
  LocationHoursComponent,
  MapComponent,
  MarketingSectionComponent,
  PageLoadingIndicatorComponent,
  PartialOutageModalComponent,
  PasswordRequirementsComponent,
  PhoneNumberComponent,
  SectionLoadingIndicatorComponent,
  SpinnerComponent,
  TabsComponent,
  TextInputComponent,
  TodaysHoursComponent,
  UnavailableItemComponent,
} from './components';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { MessagingComponent } from './components/messaging/messaging.component';
import { SocialMediaMarketingSectionComponent } from './components/social-media-marketing-section/social-media-marketing-section.component';
import {
  FocusOutlineDirective,
  InteractionStudioDirective,
  PasswordRequirementsDirective,
  TwoDigitDecimalNumberDirective,
} from './directives';
import { LegacyRedirectGuard } from './guards/legacy-redirect.guard';
import {
  BagPageService,
  CheckoutPageService,
  ConfirmationPageService,
  LocationMenuPageService,
  ProductDisplayPageService,
} from './page-services';
import {
  CalFulfillmentTimePipe,
  DefaultPipe,
  DisplayAdditionalPricePipe,
  ExpiryDataPipe,
  FavLocationPipe,
  FlavorRebalancePipe,
  GetLocationDetailsPipe,
  GetStoreHandoffModeHoursPipe,
  HandoffModeLabelPipe,
  HandoffModePrepositionPipe,
  HeatScalePipe,
  ImageResolutionPipe,
  JoinPipe,
  ModifierToFlavorModifierPipe,
  MomentPipe,
  NotNullPipe,
  OnlyModifiersOfIdPipe,
  OnlyModifiersOfTypePipe,
  OrderScheduledTimePipe,
  PaymentMethodSupportedPipe,
  PickPipe,
  PluralizePipe,
  PrependPipe,
  PushOrReplacePipe,
  ReplacePipe,
  ShouldShowCartErrorPipe,
  ShouldShowLocationErrorPipe,
  ShouldShowStoreInfoErrorPipe,
  ShouldShowStoreInfoLoadingPipe,
  ShouldShowStoreInfoPipe,
  ShowInputErrorPipe,
  SortPastOrderPipe,
  SortPipe,
  SplitPipe,
  TaxFeesPrepositionPipe,
  TipOptionsPipe,
  ValidatePasswordPipe,
} from './pipes';
import {
  ActivatedRouteSnapshotResolver,
  ActiveOfferResolver,
  DynamicScriptsResolver,
} from './resolvers';
import {
  FlavorSelectionService,
  ModifierModalElementChangeService,
} from './shared-services';
import player from 'lottie-web';

export const CommonDirectives = [
  FocusOutlineDirective,
  InteractionStudioDirective,
  TwoDigitDecimalNumberDirective,
  PasswordRequirementsDirective,
];

export const CommonComponents = [
  AnimationComponent,
  AddressLinkComponent,
  LocationAmenitiesComponent,
  IconComponent,
  BagCountComponent,
  ConnectedBagCountComponent,
  LocationHoursComponent,
  GenericErrorComponent,
  FormErrorComponent,
  TextInputComponent,
  CheckboxComponent,
  BreadcrumbComponent,
  TabsComponent,
  BadgeComponent,
  SpinnerComponent,
  GoogleRecaptchaBrandingComponent,
  PageLoadingIndicatorComponent,
  CartLocationComponent,
  CommonModalComponent,
  SectionLoadingIndicatorComponent,
  HeatScaleComponent,
  PhoneNumberComponent,
  TodaysHoursComponent,
  DesignationComponent,
  MarketingSectionComponent,
  SocialMediaMarketingSectionComponent,
  AdditionalRecommendationCardComponent,
  UnavailableItemComponent,
  MessagingComponent,
  ActiveOfferComponent,
  MarkdownComponent,
  MapComponent,
  FeatureFlagsComponent,
  FlavorCardComponent,
  PasswordRequirementsComponent,
  PartialOutageModalComponent,
  ExternalLinkConfirmationModalComponent
];
export const CommonPipes = [
  CalFulfillmentTimePipe,
  SplitPipe,
  JoinPipe,
  PrependPipe,
  DefaultPipe,
  NotNullPipe,
  PickPipe,
  ShowInputErrorPipe,
  MomentPipe,
  PluralizePipe,
  HandoffModePrepositionPipe,
  ShouldShowStoreInfoErrorPipe,
  ShouldShowStoreInfoLoadingPipe,
  ShouldShowStoreInfoPipe,
  ShouldShowLocationErrorPipe,
  ShouldShowCartErrorPipe,
  TaxFeesPrepositionPipe,
  HeatScalePipe,
  ModifierToFlavorModifierPipe,
  OnlyModifiersOfTypePipe,
  OnlyModifiersOfIdPipe,
  FlavorRebalancePipe,
  PushOrReplacePipe,
  SortPipe,
  TipOptionsPipe,
  ImageResolutionPipe,
  SortPastOrderPipe,
  OrderScheduledTimePipe,
  ExpiryDataPipe,
  FavLocationPipe,
  PaymentMethodSupportedPipe,
  GetStoreHandoffModeHoursPipe,
  ReplacePipe,
  GetLocationDetailsPipe,
  ValidatePasswordPipe,
  HandoffModeLabelPipe,
  DisplayAdditionalPricePipe
];

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    EcommModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [...CommonDirectives, ...CommonComponents, ...CommonPipes],
  exports: [...CommonDirectives, ...CommonComponents, ...CommonPipes],
  providers: [
    // common services
    ModifierModalElementChangeService,
    FlavorSelectionService,
    // resolvers
    ActivatedRouteSnapshotResolver,
    DynamicScriptsResolver,
    ActiveOfferResolver,
    // guards
    LegacyRedirectGuard,
    //page services
    LocationMenuPageService,
    ProductDisplayPageService,
    BagPageService,
    CheckoutPageService,
    ConfirmationPageService
  ]
})
export class WriCommonModule {
  public static withConfig(
    config: Config,
    legacy: {
      legacyAnalyticsServiceClass: Type<ILegacyAnalyticsService>;
      legacySeoServiceClass: Type<ILegacySeoService>;
    }
  ): ModuleWithProviders<WriCommonModule> {
    return {
      ngModule: WriCommonModule,
      providers: [
        ConfigProvider.get(config),
        EcommAPIConfigProvider.get(config.ecomm),
        EcommFeatureFlagsProvider.get(config.featureFlags),
        LegacyAnalyticsServiceProvider.get(legacy.legacyAnalyticsServiceClass),
        LegacySeoServiceProvider.get(legacy.legacySeoServiceClass)
      ]
    };
  }
}
