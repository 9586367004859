<!-- -----For Pay Credit Card Option----- -->
<ng-container *ngIf="creditCardForm !== null">
  <div class="creditcard-payment-method">
    <ng-container *ngIf="vaultedCreditCardView && !hasNoSessionDetails">
      <div *ngFor="let addedCard of addedCreditCards">
        <div
          class="added-creditcard"
          data-testid="added-creditcard-wrapper"
          [ngClass]="{'credit-card-payment-unavailable':!isVaultedCreditCardPaymentSupported || !vaultedCreditCardPay}"
        >
          <div class="vaulted-check-box-wrapper">
            <div class="checkbox-redeemed-wrapper">
              <div class="flex card-icon-wrapper">
                <wri-icon icon="wri-credit-card"></wri-icon>
              </div>
              <div class="check-box-balance-wrapper">

                <wri-checkbox
                  [defaultTemplate]="false"
                  [id]="addedCard.id | prepend : 'added-credit-card-'"
                  [attr.data-testid]="addedCard.id | prepend : 'added-credit-card-'"
                  class="span-full-width-lg"
                  label="{{ addedCard.credit.cardType }}"
                  [extraLabelTemplate]="'ending in '+ addedCard.credit.alias"
                  [value]="addedCreditCardForm.controls[addedCard.id]?.value"
                  (change)="
                    toggleAddedCardStatus(addedCard.id, $event.target.checked)
                  "
                  tabindex="0"
                  customClass="paymentLabel"
                  [disabled]="!isVaultedCreditCardPaymentSupported || !vaultedCreditCardPay"
                  [ngClass]="{
                    'credit-card-unavailable-label': !isVaultedCreditCardPaymentSupported || !vaultedCreditCardPay
                  }"
                ></wri-checkbox>
                <div *ngIf="addedCreditCardForm.value[addedCard.id]"
                     class="creditcard-payment-method-redeemed-amount">
                  Amount: {{ addedCard.requestedAmount | currency }}
                </div>
              </div>
              <div class="credit-card-unavailable" *ngIf="!isVaultedCreditCardPaymentSupported || !vaultedCreditCardPay" data-testid="vaulted-credit-card-disabled">
                Credit/debit card payment is currently unavailable at this location
              </div>
              <div *ngIf="addedCard?.credit?.expiryDate | expiryData" class="creditcard-payment-method-exp-date">
                Expired  {{ addedCard?.credit?.expiryDate?.month }}/{{
                  addedCard?.credit?.expiryDate?.year
                }}
              </div>
            </div>

            <div *ngIf="addedCard.type === 'anonymous'" class="creditcard-payment-method-remove-vaulted-card-text"
              (click)="remove(addedCard.type, addedCard.id)" [attr.data-testid]="
                    addedCard.id | prepend : 'remove-added-credit-card-for-'
                  ">
              Remove card
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div
      class="creditcard-payment-method-form payment-method-card"
      [ngClass]="{'credit-card-payment-unavailable':!isCreditCardPaymentSupported}"
      >
      <div>
        <div class="check-box-wrapper">

          <div class="flex card-icon-wrapper">
            <wri-icon icon="wri-credit-card"></wri-icon>
          </div>

          <wri-checkbox
            [defaultTemplate]="false"
            [id]="'credit-card-pay'"
            class="span-full-width-lg"
            label="Add a credit/debit card"
            [extraLabelTemplate]="value? ( 'Amount: ' + (totalPrice|currency : 'USD' : 'symbol')): null"
            [extraLabelClass]="'extra-label-class-bold'"
            [value]="value"
            (change)="onCheckboxUpdate($event.target.checked)"
            customClass="paymentLabel"
            [disabled]="!isCreditCardPaymentSupported"
            [ngClass]="{
              'credit-card-unavailable-label': !isCreditCardPaymentSupported
            }"
          ></wri-checkbox>
        </div>
        <div class="credit-card-unavailable" *ngIf="!isCreditCardPaymentSupported">
          Credit/debit card payment is currently unavailable at this location
        </div>
        <ng-container *ngIf="value">
          <ng-template
            *ngIf="hasNoSessionDetails"
            [ngTemplateOutlet]="creditCardError"
          ></ng-template>
          <div
            class="fiserv-guest-guest-checkout-form"
            id="payment-guest-checkout-container"
            data-testid="fiserv-form-container"
          ></div>
        </ng-container>
      </div>
      <ng-container *ngIf="value && isLoading">
        <div class="loading-wrapper">
          <wri-section-loading-indicator gridAutoRows="minmax(450px,1fr)">
          </wri-section-loading-indicator>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- ----- Credit Card payment error----- -->
  <ng-template #creditCardError>
    <div class="credit-payment-error" data-testid="error-loading-fiserv-form">
      <span
        >We encountered an error loading credit card payments. Please refresh
        the page and try again.</span
      >
    </div>
  </ng-template>
</ng-container>
